import * as React from "react";

import Layout from "../components/layout";
import { useTranslation } from "react-i18next";
import { TranslatedLink } from "gatsby-plugin-translate-urls";
import { graphql } from "gatsby";

const NotFoundPage = ({ location }) => {
  const { t } = useTranslation();
  return (
    <Layout
      location={location}
      seoTitle={t("404.title")}
      seoDescription={t("404.message")}
    >
      <main className="page-error">
        <div className="container">
          <section className="error-section">
            <h1 className="error-code">404</h1>
            <p className="error-message">{t("404.message")}</p>
            <img
              src="/images/dead-end.png"
              alt="page not found"
              className="error-img"
            />
            <TranslatedLink to="/" className="btn btn-primary btn-error-action">
              {t("404.returnHome")}
            </TranslatedLink>
          </section>
        </div>
      </main>
    </Layout>
  );
};

export default NotFoundPage;

export const query = graphql`
  query ($language: String!) {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
